
import XLSX, { WorkSheet } from 'xlsx';
import {
  computed, defineComponent, ref, watch,
} from 'vue';
import Modal from '@/components/UI/Modal/index.vue';
import { TradeObjectFromFile } from '@/types/UploadFiles';
import api from '@/api';

export default defineComponent({
  name: 'uploadNewItems',
  components: {
    Modal,
  },
  setup() {
    const cols = [
      {
        label: 'Адрес',
        field: 'Адрес',
      },
      {
        label: 'Тип строения',
        field: 'Тип строения',
      },
      {
        label: 'Статус объекта',
        field: 'Статус объекта',
      },
      {
        label: 'Год постройки',
        field: 'Год постройки',
      },
      {
        label: 'Код ТНО УГНС',
        field: 'Код ТНО УГНС',
      },
      {
        label: 'Идентификатор инспектора',
        field: 'Идентификатор инспектора',
      },
    ];

    const file = ref<File | null>(null);
    const decodedFile = ref<{[key: string]: WorkSheet} | null>(null);

    const label = computed(() => {
      if (file.value) return `${file.value.name.slice(0, 10)}...`;
      return 'Обзор...';
    });

    function decodeFile() {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const data = e?.target?.result;
        if (data) {
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          let result: WorkSheet | null = null;
          const sheetName = workbook.SheetNames[0];
          const roa: TradeObjectFromFile[] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 0 });
          if (roa.length) {
            result = roa;
          } else result = null;
          decodedFile.value = result;
        }
      };
      fileReader.readAsBinaryString(file.value as Blob);
    }

    const loading = ref(false);
    async function onSave() {
      if (!file.value) return;
      loading.value = true;
      try {
        await api.objectsOfTrade.loadfile(file.value);
        // eslint-disable-next-line no-alert
        alert('Торговые объекты успешно загружены');
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert(`Ошибка при загрузке торговых объектов.\n${e?.response?.data || 'Неизвестная ошибка'}`);
      } finally {
        loading.value = false;
      }
    }
    async function onClickDownloadTpl() {
      await api.objectsOfTrade.downloadTemplate();
    }

    watch(file, (val) => {
      if (val) decodeFile();
    });

    return {
      file, label, decodedFile, cols, onSave, onClickDownloadTpl, loading,
    };
  },
});
