
import {
  defineComponent, onMounted, reactive, ref, watch,
} from 'vue';
import { useRouter } from 'vue-router';
import PageControls from '@/components/PageControls.vue';
import Tabs from '@/components/UI/Tabs/index.vue';
import ContainerHeader from '@/components/ContainerHeader.vue';
import ColorSelector from '@/components/UI/ColorSelector.vue';
import searchIcon from '@/assets/images/ic-search.svg';
import debounce from 'lodash.debounce';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'RegistryHeader',
  components: {
    PageControls,
    Tabs,
    ContainerHeader,
    ColorSelector,
  },
  props: ['filters'],
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const currentTab = ref(2);
    const showUploadNewItems = ref(false);
    const tabs = [
      {
        name: 'Объекты торговли',
        onClick() { router.push('/registry/trade-objects'); },
      },
      {
        name: 'Налоговые органы',
        onClick() { router.push('/registry/ugns'); },
      },
      {
        name: 'Налогоплательщики',
        onClick() { router.push('/registry/nalogpayers'); },
      },
    ];

    const tabClick = (e: number) => {
      currentTab.value = e;
    };

    // eslint-disable-next-line vue/no-setup-props-destructure
    const filters$ = props.filters;

    onMounted(() => {
      window.ymaps.ready(() => {
        // const suggestView = new (window.ymaps as any).SuggestView('filterAddress');
      });
    });

    const searchHints = reactive({
      display: false,
      rows: [] as string[],
    });

    const delayedGetSuggestions = debounce(async () => {
      let request = filters$.filter;
      request = request.toLowerCase().includes('киргизия') ? request : `киргизия ${request}`;
      const obj: any = await (window.ymaps as any).suggest(request);
      searchHints.rows = obj.map((item: any) => ({ name: item.displayName, value: item.value }));
    }, 500);
    watch(
      () => [filters$.filter],
      () => {
        delayedGetSuggestions();
      },
    );

    const storeInput = (value = '') => {
      store.commit('registry/SET_REGISTRY_SEARCH', { value, entity: 'nalogPayersSearch' });
    };

    return {
      filters$,
      searchHints,
      tabs,
      currentTab,
      tabClick,
      searchIcon,
      showUploadNewItems,
      storeInput,
    };
  },
});
