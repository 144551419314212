
/* eslint-disable no-use-before-define */
import {
  computed,
  defineComponent, reactive, ref,
} from 'vue';
import { NalogPayer } from '@/types/NalogPayer';
import NalogpayersHeader from '@/components/views/nalogpayers/NalogpayersHeader.vue';
import UploadNewItems from '@/components/Modals/uploadNewItems.vue';
import api from '@/api';
import ViewportObserver from '@/components/ViewportObserver.vue';
import usePagination from '@/modules/usePagination';
import { useStore } from 'vuex';
import { TradeObjectPreview } from '@/types/ObjectOfTrade';
import { ObjectStatus } from '@/constants/objectStatuses';
import { badgeProps } from '@/utils';

export default defineComponent({
  name: 'RegistryIndex',
  components: {
    UploadNewItems, NalogpayersHeader, ViewportObserver,
  },
  setup() {
    const error = ref('');
    const showUploadModal = ref(false);
    const store = useStore();
    const isRoleAdmin = store.state.auth.isAdmin;
    const changeStatusTaxPayer = {
      component: (row: TradeObjectPreview) => ({
        name: 'ChangeStatus',
        ...badgeProps(row.objectStatus),
        'onUpdate:status': async (objectStatus: string) => {
          await api.taxPayers.saveById(row.id, { objectStatus });
          nalogPayers.pagination.reset();
          await nalogPayers.fetch();
        },
      }),
    };
    const cols = [
      isRoleAdmin === true ? changeStatusTaxPayer : '',
      {
        label: 'ИНН',
        // width: '30%',
        component: (row: NalogPayer) => ({
          name: 'router-link',
          class: 'link',
          value: row.inn,
          to: { name: 'registry-nalogpayers-object-id', params: { payerId: row.id } },
        }),
      },
      {
        label: 'Юридическое наименование',
        // width: '30%',
        display: (row: NalogPayer) => row.legalName,
      },
      {
        label: 'Торговое наименование',
        // width: '30%',
        display: (row: NalogPayer) => row.tradeName,
      },
      {
        label: 'Код УГНС',
        // width: '30%',
        display: (row: NalogPayer) => row.ugnsCode,
      },
      {
        label: 'Адрес',
        // width: '30%',
        display: (row: NalogPayer) => row.address,
      },
      {
        label: 'Инспектор',
        display: (row: NalogPayer) => row.inspector || 'Не определен',
      },
    ];

    const nalogPayers = usePagination({
      filters: {
        filter: computed(() => store.getters['registry/GET_REGISTRY_SEARCH']('nalogPayersSearch')),
        status: {} as ObjectStatus,
      },
      immediateFilters: ['status'],
      delayedFilters: ['filter'],
      rows: [] as NalogPayer[],
      async fetchContents() {
        const { filter, status } = this.filters as { filter: string, status: ObjectStatus };
        const statusValue = status?.value;
        const params = {
          ...filter && { filter },
          ...(typeof statusValue === 'string') && { status: statusValue },
          ...nalogPayers.pagination.params,
          duplicate: store.state.auth.isAdmin,
          pageSize: 100,
        };
        return (await api.taxPayers.getAll(params)).data;
      },
    });
    nalogPayers.fetch();

    const paginateHandler = (pageNumber: number) => {
      nalogPayers.pagination.page = pageNumber - 1;
      nalogPayers.fetch(false, true);
    };

    const pagination = reactive({
      pages: computed(() => nalogPayers.pagination.totalPages),
      currentPage: computed(() => nalogPayers.pagination.page + 1),
      fetching: computed(() => nalogPayers.fetching),
    });

    return {
      nalogPayers, error, cols, showUploadModal, paginateHandler, pagination,
    };
  },
});
