import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_ColorSelector = _resolveComponent("ColorSelector")!
  const _component_UiInput = _resolveComponent("UiInput")!
  const _component_PageControls = _resolveComponent("PageControls")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Tabs, {
      tabs: _ctx.tabs,
      activeTabIndex: _ctx.currentTab,
      onTabClick: _ctx.tabClick
    }, null, 8, ["tabs", "activeTabIndex", "onTabClick"]),
    _createVNode(_component_PageControls, null, {
      default: _withCtx(() => [
        _createVNode(_component_ColorSelector, {
          modelValue: _ctx.filters$.status,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters$.status) = $event)),
          disabled: "",
          exclude: _ctx.$store.state.auth.isAdmin ? '' : 'DUPLICATE'
        }, null, 8, ["modelValue", "exclude"]),
        _createVNode(_component_UiInput, {
          modelValue: _ctx.filters$.filter,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.storeInput($event))),
          search: "",
          label: "Адрес, ИНН налогоплательщика или торговое название",
          placeholder: "г.Бишкек",
          inputId: "filterAddress",
          "use-options": "",
          options: _ctx.searchHints,
          "onUpdate:selectedRow": _cache[2] || (_cache[2] = ($event: any) => (_ctx.storeInput($event?.value))),
          style: {"width":"50rem"}
        }, null, 8, ["modelValue", "options"])
      ]),
      _: 1
    })
  ]))
}